import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import {
  graphQlPaginationMetaProps,
  usePagination,
} from '@lib/hooks/usePagination';
import { useQuery } from '@lib/hooks';
import { usePaginationReset } from '@lib/hooks/usePaginationReset';
import ProjectDetailsPage, { useProjectDetailsPage } from '@modules/projects/components/project-details/ProjectDetailPage';
import ProjectList from '@modules/organizations/components/organization-details/fragments/ProjectList';
import { Grid } from '@mui/material';
import Select from '@components/form/Select';
import { FILTER_ORGANIZATION_ROLE } from '@modules/projects/config';
import { useState, useMemo } from 'react';

export default function OrganizationProjects(props) {
  const { organization } = props;
  const { t, i18n } = useTranslation();
  const { openProjectDetailsPage } = useProjectDetailsPage();
  const [organizationRoleFilter, setOrganizationRoleFilter] = useState('executing');
  const filters = queryFilters(organizationRoleFilter, organization);

  const {
    fetchProps,
    mergePaginationProps,
    resetPagination,
  } = usePagination({ pageSize: 10 });
  const { data: itemsData, loading } = useQuery(FETCH_ITEMS, {
    variables: {
      ...fetchProps,
      sort: ['projectStart:desc', 'index1:asc'],
      filters,
      locale: i18n.language,
    },
  });
  usePaginationReset(
    resetPagination,
    fetchProps.pagination.pageSize,
  );

  const projects = itemsData?.calculatedProjects.data ?? [];

  const handleOpenProject = (id) => {
    openProjectDetailsPage(id, 'list');
  };

  return (
    <>
      <Grid container mb={2}>
        <Grid item md={3} xs={12}>
          <Select
            fullWidth
            items={FILTER_ORGANIZATION_ROLE.map((item) => ({
              ...item,
              label: t(`modules.projects.components.FilterBar.organizationRoles.${item.id}`),
            }))}
            label={t('modules.projects.components.FilterBar.projectManagementType')}
            labelProp="label"
            onChange={(e) => setOrganizationRoleFilter(e.target.value)}
            size="small"
            sx={{
              backgroundColor: 'background.paper',
            }}
            value={organizationRoleFilter}
          />
        </Grid>
      </Grid>
      <ProjectList
        {...mergePaginationProps(itemsData?.calculatedProjects.meta)}
        loading={loading}
        onItemClick={handleOpenProject}
        projects={projects}
      />
      <ProjectDetailsPage />
    </>
  );
}

OrganizationProjects.tabFilter = () => true;

const FETCH_ITEMS = gql`
  query Fetch(
    $locale: String!,
    $filters: ProjectFiltersInput!,
    $pagination: PaginationArg,
    $sort: [String]!,
  ) {
    calculatedProjects(
      sort: $sort,
      filters: $filters,
      pagination: $pagination,
      locale: $locale,
      template: "extended",
    ) {
      data
      meta {
        ${graphQlPaginationMetaProps}
      }
    }
  }`;

function queryFilters(organizationRoleFilter, organization) {
  const queryFilter = {};
  if (organizationRoleFilter === 'executing') {
    queryFilter.or = [
      {
        primary_executing_org: {
          id: { eq: organization.id },
        },
      },
      {
        executing_orgs: {
          id: { eq: organization.id },
        },
      },
    ];
  } else if (organizationRoleFilter === 'beneficiary') {
    queryFilter.final_beneficiary_org = {
      id: { eq: organization.id },
    };
  }

  return queryFilter;
}
