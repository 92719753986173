export const DEFAULT_VIEW = 'maplist';
export const DEFAULT_VIEW_MOBILE = 'map';

export const ORGANIZATION_TAG_REFERENCES = [
  {
    id: 'sector',
    label: 'Branche',
  },
  {
    id: 'products',
    label: 'Produkte',
  },
  {
    id: 'services',
    label: 'Dienstleistungen',
  },
  {
    id: 'energy-storage-type',
    label: 'Energiespeichertyp',
  },
];

export const DEFAULT_ORGANIZATION_TYPES = [
  'researchInstitution',
  'instituteCentralFacilityChair',
  'company',
];
