import { useTranslation } from 'react-i18next';
import OrganizationHierarchy from '@components/OrganizationHierarchy';
import { getFileType } from '@utils';
import {
  FiExternalLink,
  FiPhone,
  FiMail,
} from 'react-icons/fi';
import {
  Box,
  Button,
  Chip,
  Divider,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import OrganizationLegend from '@modules/member/components/OrganizationLegend';
import { filesize } from 'filesize';
import Link from '@modules/projects/components/project-details/fragments/Link';
import { useOrganizationDetailsPage } from '@modules/organizations/components/organization-details/OrganizationDetailPage';

export default function OrganizationGeneral(props) {
  const {
    organization,
  } = props;
  const { t, i18n } = useTranslation();
  const { openOrganizationDetailsPage } = useOrganizationDetailsPage();

  const isOrgLiquidated = organization.liquidationState === 'liquidated';
  const files = organization.organization_files
    .filter((f) => f.languages.find((l) => l.code === i18n.language));

  const handleOpenOrganization = (id) => () => {
    openOrganizationDetailsPage(id, 'maplist');
  };
  const handleOpenOrganization2 = (event, id) => {
    openOrganizationDetailsPage(id, 'maplist');
  };

  return (
    <>
      <Box>
        {organization.title.map((title, index) => (
          <ListItemButton
            key={title.id}
            onClick={handleOpenOrganization(title.id)}
            sx={{ px: 0, py: 0.25 }}
          >
            <Typography
              color={title.isLiquidated ? 'primary.main' : null}
              sx={index < organization.title.length - 1 ? { fontWeight: 'normal' } : null}
              variant="subtitle2"
            >
              {title.name}
              {title.isLiquidated && (
                <Chip
                  color="primary"
                  label={t('modules.organizations.components.OrganizationDetailPage.general.liquidated', {
                    date: title.liquidationDate
                      ? DateTime.fromISO(title.liquidationDate)
                        .setLocale(i18n.language)
                        .toFormat(t('shared.format.date.short'))
                      : 'k. A.',
                  })}
                  size="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
            {index < organization.title.length - 1 && (
              <ListItemIcon sx={{ ml: 1 }}>
                <FiExternalLink />
              </ListItemIcon>
            )}
          </ListItemButton>
        ))}
      </Box>
      {(organization.addressStreet
          || organization.addressZipCode
          || organization.addressCity)
        && (
          <Box mt={2}>
            {isOrgLiquidated && (
              <Typography color="text.disabled" variant="caption">
                {t('modules.organizations.components.OrganizationDetailPage.general.lastKnownAddress')}
              </Typography>
            )}
            <Typography
              color={isOrgLiquidated ? 'text.disabled' : null}
              variant="body2"
            >
              {organization.addressStreet}
              {' '}
              {organization.addressStreetNumber}
            </Typography>
            <Typography
              color={isOrgLiquidated ? 'text.disabled' : null}
              variant="body2"
            >
              {organization.addressSuffix1}
            </Typography>
            <Typography
              color={isOrgLiquidated ? 'text.disabled' : null}
              variant="body2"
            >
              {organization.addressSuffix2}
            </Typography>
            <Typography
              color={isOrgLiquidated ? 'text.disabled' : null}
              variant="body2"
            >
              {organization.addressZipCode}
              {' '}
              {organization.addressCity}
            </Typography>
            <Typography
              color={isOrgLiquidated ? 'text.disabled' : null}
              variant="body2"
            >
              {organization.addressCountry}
            </Typography>
          </Box>
        )}
      {organization.website && (
        <Box mt={3}>
          <Link
            href={organization.website}
            name={t('modules.organizations.components.OrganizationDetailPage.general.goToWebsite')}
          />
        </Box>
      )}
      {organization.primaryContact && (
        <Box mt={3}>
          <Typography variant="subtitle2">
            {t('modules.organizations.components.OrganizationDetailPage.general.generalContact')}
          </Typography>
          {organization.primaryContact.name && (
            <Typography variant="body2">
              {organization.primaryContact.name}
            </Typography>
          )}
          {organization.primaryContact.phone && (
            <Button
              component="a"
              href={`tel:${organization.primaryContact.phone.replaceAll(' ', '')}`}
              size="small"
              startIcon={<FiPhone />}
            >
              {organization.primaryContact.phone}
            </Button>
          )}
          {organization.primaryContact.email && (
            <Button
              component="a"
              href={`mailto:${organization.primaryContact.email}`}
              size="small"
              startIcon={<FiMail />}
            >
              {organization.primaryContact.email}
            </Button>
          )}
        </Box>
      )}
      {files.length > 0 && (
        <Box>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6">
            {t('modules.organizations.components.OrganizationDetailPage.general.downloads')}
          </Typography>
          {files.map((file) => (
            <Link
              key={file.id}
              href={file.file.url}
              info={`${getFileType(file.file.name)} (${filesize(file.file.size)})`}
              name={file[`title_${i18n.language}`] ?? file.file.name}
            />
          ))}
        </Box>
      )}
      <Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6">
          {t('modules.organizations.components.OrganizationDetailPage.general.organizationChart')}
        </Typography>
        <Typography component="div" variant="caption">
          {t('modules.organizations.components.OrganizationDetailPage.general.organizationChartNote')}
        </Typography>
        <OrganizationHierarchy
          currentOrgId={organization.id}
          id={organization.topLevelOrganizationId}
          isPublic
          noPress
          onClick={handleOpenOrganization2}
        />
        <Box mt={5}>
          <OrganizationLegend hidePress />
        </Box>
      </Box>
    </>
  );
}

OrganizationGeneral.tabFilter = () => true;
