import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { useQuery } from '@lib/hooks';
import { ORGANIZATION_TAG_REFERENCES } from '@modules/organizations/config';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import TagMap from '@components/tags/TagMap';
import { DateTime } from 'luxon';

export default function OrganizationTags(props) {
  const { organization } = props;
  const { t, i18n } = useTranslation();

  const { data, loading } = useQuery(GET_DATA, {
    variables: {
      references: ORGANIZATION_TAG_REFERENCES.map((x) => x.id),
    },
  });

  return (
    <>
      {organization.facts.length > 0 && (
        <Box>
          <Typography mb={2} variant="h6">
            {t('modules.organizations.components.OrganizationDetailPage.tags.companyData')}
          </Typography>
          {organization.facts.map((org) => (
            <Paper key={org.id} sx={{ p: 1, mb: 3 }} variant="outlined">
              <Typography
                gutterBottom
                sx={{ fontWeight: 'bold' }}
                variant="subtitle2"
              >
                {org.name}
              </Typography>
              {/* <Typography variant="body2">
                {t('modules.organizations.components.OrganizationDetailPage.tags.facts.year', {
                  year: org.year,
                })}
              </Typography> */}
              <Box>
                <Grid container spacing={1}>
                  {org.numOfEmployees && (
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                        {t('modules.organizations.components.OrganizationDetailPage.tags.facts.numOfEmployees', {
                          year: org.year,
                        })}
                      </Typography>
                      <Typography variant="body2">
                        {t(`enums.organization.numOfEmployees.${org.numOfEmployees}`)}
                      </Typography>
                    </Grid>
                  )}
                  {org.turnoverPerYear && (
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                        {t('modules.organizations.components.OrganizationDetailPage.tags.facts.turnoverPerYear', {
                          year: org.year,
                        })}
                      </Typography>
                      <Typography variant="body2">
                        {t(`enums.organization.turnoverPerYear.${org.turnoverPerYear}`)}
                      </Typography>
                    </Grid>
                  )}
                  {org.balanceSheetTotal && (
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                        {t('modules.organizations.components.OrganizationDetailPage.tags.facts.balanceSheetTotal', {
                          year: org.year,
                        })}
                      </Typography>
                      <Typography variant="body2">
                        {t(`enums.organization.balanceSheetTotal.${org.balanceSheetTotal}`)}
                      </Typography>
                    </Grid>
                  )}
                  {org.category && (
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                        {t('modules.organizations.components.OrganizationDetailPage.tags.facts.category', {
                          year: org.year,
                        })}
                        <br />
                        <span style={{ fontWeight: 'normal' }}>
                          {t('modules.organizations.components.OrganizationDetailPage.tags.facts.categoryNote')}
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        {t(`enums.organizationCategory.${org.category}`)}
                      </Typography>
                    </Grid>
                  )}
                  {org.dateOfIncorporation && (
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                        {t('modules.organizations.components.OrganizationDetailPage.tags.facts.dateOfIncorporation')}
                      </Typography>
                      <Typography variant="body2">
                        {DateTime.fromISO(org.dateOfIncorporation)
                          .setLocale(i18n.language)
                          .toFormat(t('shared.format.date.short'))}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          ))}
        </Box>
      )}
      {organization.tags.length > 0 && (
        <Box>
          {organization.facts.length > 0 && <Divider sx={{ my: 3 }} />}
          <Typography mb={2} variant="h6">
            {t('modules.organizations.components.OrganizationDetailPage.tags.tags')}
          </Typography>
          {loading && [1, 2, 3].map((key) => (
            <Box key={key} mb={2}>
              <Box alignItems="center" display="flex">
                <Typography sx={{ flex: 1 }} variant="subtitle2">
                  <Skeleton />
                </Typography>
              </Box>
              <Box>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Box>
            </Box>
          ))}
          {!loading && (
            <TagMap
              entityTags={organization.tags}
              tagItems={data?.tagItems.data}
            />
          )}
        </Box>
      )}
    </>
  );
}

OrganizationTags.tabFilter = (organization) => (
  organization.facts.length > 0
  || organization.tags.length > 0
);

const TAG_ITEM_ATTRIBUTES = `
name_de
name_en
index
referenceId
isAbbreviationUsedAsLabel
tag_item_abbreviations(
  sort: ["index"],
  pagination: {limit: 1}
) {
  data {
    id
    attributes {
      name_de
      name_en
    }
  }
}
`;

function getGraphQlSchemaForTagItem(depth = 0) {
  if (depth >= 25) {
    return `
      id
      attributes {
        ${TAG_ITEM_ATTRIBUTES}
      }
    `;
  }

  return `
    id
    attributes {
      ${TAG_ITEM_ATTRIBUTES}
      child_items(
        sort: ["index", "name_de", "id"],
        pagination: { limit: 100 }
      ) {
        data {
          ${getGraphQlSchemaForTagItem(depth + 1)}
        }
      }
    }
  `;
}

const GET_DATA = gql`
  query TagRoot($references: [String]!) {
    tagItems(
      sort: ["index", "name_de"],
      filters: {
        referenceId: {in: $references}
      }
    ) {
      data {
        ${getGraphQlSchemaForTagItem()}
      }
    }
  }
`;
