import {
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import {
  ORGANIZATION_TYPE_BGCOLORS,
  ORGANIZATION_TYPE_COLORS,
} from '@theme';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export default function OrganizationLegend({ hidePress }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      alignItems="center"
      container
      display="flex"
      spacing={1}
      sx={{
        transition: theme.transitions.create(['opacity']),
        opacity: 0.35,
        '&:hover, &:focus': {
          opacity: 1,
        },
      }}
    >
      <Grid item xs="auto">
        <Typography variant="caption">
          {t('modules.member.components.OrganizationLegend.legend')}
        </Typography>
      </Grid>
      {ORGANIZATION_TYPES.filter((o) => (hidePress ? o !== 'press' : true)).map((type) => (
        <Grid key={type} item xs="auto">
          <Chip
            label={t(`enums.organizationType.${type}`)}
            size="small"
            sx={{
              color: ORGANIZATION_TYPE_COLORS[type],
              bgcolor: ORGANIZATION_TYPE_BGCOLORS[type],
              fontSize: 12,
              border: 'none',
              px: 0.5,
            }}
            variant="outlined"
          />
        </Grid>
      ))}
    </Grid>
  );
}

const ORGANIZATION_TYPES = [
  'researchInstitution',
  'instituteCentralFacilityChair',
  'subgroup',
  'company',
  'location',
  'department',
  'federalMinistry',
  'referat',
  'projectSponsor',
  'workUnit',
  'press',
];
