/* eslint-disable no-nested-ternary,react/no-array-index-key */
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { getAddressCountry } from '@utils';
import ResponsiveImage from '@components/ResponsiveImage';
import LiquidatedTag from '@components/LiquidatedTag';
import MarkdownText from '@components/MarkdownText';

export default function OrganizationHead(props) {
  const {
    organization,
  } = props;
  const { t, i18n } = useTranslation();

  const cellStyle = {
    verticalAlign: 'top',
    p: 0,
    whiteSpace: 'nowrap',
    border: 'none',
  };
  const cellValueStyle = {
    p: 0,
    border: 'none',
  };

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item md={8} xs={12}>
        <Box>
          {organization.title.map(({ id, name, isLiquidated }, index) => (
            <Typography
              key={id}
              color={isLiquidated ? 'primary.main' : null}
              component={index < organization.title.length - 1 ? 'div' : 'h1'}
              gutterBottom
              sx={index < organization.title.length - 1 ? { fontWeight: 'normal' } : null}
              variant="h6"
            >
              {name}
              {isLiquidated && (
                <LiquidatedTag sx={{ ml: 1 }} />
              )}
            </Typography>
          ))}
          <Box>
            <MarkdownText
              value={organization.description}
              variant="body2"
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md="auto" xs={12}>
        {organization.logos.map((logo) => (
          <ResponsiveImage
            key={logo.url}
            mb={2}
            src={logo.url}
          />
        ))}
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  ...cellStyle, width: '1px', pr: 1, pb: 1,
                }}
              >
                <Typography component="div" variant="caption">
                  {t('modules.organizations.components.OrganizationDetailPage.head.abbreviation')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...cellValueStyle, pb: 1,
                }}
              >
                <Box alignItems="center" display="flex" flexWrap="wrap">
                  {organization.abbreviations.map(({ abbreviation, isLiquidated }, index) => (
                    <Fragment key={abbreviation}>
                      <Typography color={isLiquidated ? 'primary.main' : null} sx={{ fontWeight: 'bold' }} variant="caption">
                        {abbreviation}
                        {isLiquidated && (
                          <LiquidatedTag sx={{ ml: 1 }} />
                        )}
                      </Typography>
                      {index < organization.abbreviations.length - 1 && (
                        <Typography sx={{ fontWeight: 'bold', mx: 1 }} variant="caption">
                          -
                        </Typography>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  ...cellStyle, width: '1px', pr: 1, pb: 1,
                }}
              >
                <Typography component="div" variant="caption">
                  {t('modules.organizations.components.OrganizationDetailPage.head.location')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...cellValueStyle, pb: 1,
                }}
              >
                {(organization.location.zip
                  || organization.location.city
                  || organization.location.state
                  || organization.location.country)
                  ? (
                    <>
                      <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                        {organization.location.zip}
                        {' '}
                        {organization.location.city}
                      </Typography>
                      <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                        {organization.location.state}
                      </Typography>
                      <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                        {getAddressCountry(organization)}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="div" variant="caption">
                      k. A.
                    </Typography>
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {organization.category && (
          <Box>
            <Chip
              label={t(
                `enums.organizationCategory.${organization.category}`,
              )}
              size="small"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
