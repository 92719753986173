/* eslint-disable no-nested-ternary */
import {
  Box,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import TablePagination from '@components/TablePagination';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { renderMoneyValue } from '@utils';
import { PROJECT_TYPES_ABBREVIATIONS } from '@modules/projects/config';

export default function ProjectList(props) {
  const {
    legend,
    loading,
    onItemClick,
    pagination,
    projects,
  } = props;
  const theme = useTheme();
  const paginationRef = useRef();
  const { t, i18n } = useTranslation();

  const tableColumnsLevel2 = [
    <TableCell key="abbreviation">
      {t('modules.projects.components.ExtendedProjectList.columns.acronym')}
    </TableCell>,
    <TableCell key="joint-project-name">
      {t('modules.projects.components.ExtendedProjectList.columns.projectName')}
    </TableCell>,
    <TableCell key="projectType">
      {t('modules.projects.components.ExtendedProjectList.columns.projectType')}
    </TableCell>,
    <TableCell key="name">
      {t('modules.projects.components.ExtendedProjectList.columns.subProjectName')}
    </TableCell>,
    <TableCell key="fördergeber">
      {t('modules.projects.components.ExtendedProjectList.columns.sponsor')}
    </TableCell>,
    <TableCell key="laufzeitVon">
      {t('modules.projects.components.ExtendedProjectList.columns.runningTimeFrom')}
    </TableCell>,
    <TableCell key="LaufzeitBiz">
      {t('modules.projects.components.ExtendedProjectList.columns.termUntil')}
    </TableCell>,
    <TableCell key="fördersumme" sx={{ textAlign: 'right' }}>
      {t('modules.projects.components.ExtendedProjectList.columns.fundingAmount')}
    </TableCell>,
  ];

  return (
    <Box
      sx={{
        // Css container size reference
        containerType: 'inline-size',
      }}
    >
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {tableColumnsLevel2}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && [...Array(10).keys()].map((index) => (
              <TableRow key={index}>
                <TableCell colSpan={tableColumnsLevel2.length} sx={{ opacity: 1 / (index + 1) }}>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))}
            {!loading && projects.length === 0 && (
              <TableRow>
                <TableCell colSpan={tableColumnsLevel2.length} sx={{ py: 2 }}>
                  <Typography color="text.disabled" variant="body2">
                    {t('modules.organizations.components.OrganizationDetailPage.projects.noProjectsMessage')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!loading && projects.length > 0 && projects.map((item) => (
              <RowItem
                key={item.id}
                i18n={i18n}
                item={item}
                onClick={onItemClick}
                t={t}
                theme={theme}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div ref={paginationRef}>
        {pagination.total > pagination.pageSize && (
          <TablePagination {...props} />
        )}
      </div>
      {legend}
    </Box>
  );
}

function RowItem(props) {
  const {
    t,
    i18n,
    item,
    onClick,
  } = props;

  const handleClick = (id) => () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <TableRow
      hover
      onClick={handleClick(item.id)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell
        sx={{
          width: '1px',
          whiteSpace: 'nowrap',
        }}
      >
        {item.title}
      </TableCell>
      <TableCell>
        {item.name}
      </TableCell>
      <TableCell>
        {PROJECT_TYPES_ABBREVIATIONS[item.projectType]}
        {item.isSubProject && ` ${item.abbreviation}`}
      </TableCell>
      <TableCell>
        {item.isSubProject && (
          <span>
            {item.name_de}
          </span>
        )}
      </TableCell>
      <TableCell>
        {item.projectFundingOrgs}
      </TableCell>
      <TableCell>
        {DateTime
          .fromISO(item.projectStart)
          .setLocale(i18n.language)
          .toFormat(t('shared.format.date.short'))}
      </TableCell>
      <TableCell>
        {DateTime
          .fromISO(item.projectEnd)
          .setLocale(i18n.language)
          .toFormat(t('shared.format.date.short'))}
      </TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {item.fundingAmount ? renderMoneyValue(item.fundingAmount, {
          maximumFractionDigits: 0,
        }) : null}
      </TableCell>
    </TableRow>
  );
}
