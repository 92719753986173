import { useCallback, useEffect } from 'react';
import { useLazyQuery } from '@lib/hooks';
import { gql } from '@apollo/client';

export function useExternalChangeRequests(id, showChangeRequests) {
  const [fetchItems, { data }] = useLazyQuery(FETCH);

  useEffect(() => {
    if (!id || !showChangeRequests) {
      return;
    }

    fetchItems({ variables: { id } });
  }, [fetchItems, id, showChangeRequests]);

  const applyChangeRequests = useCallback((project) => {
    if (!project) {
      return null;
    }

    if (!showChangeRequests || !data) return project;

    const newProject = structuredClone(project);

    data.externalChangeRequests.data.forEach((item) => {
      const { field, type, value } = item.attributes;

      switch (type) {
        default:
          newProject.attributes[field] = value?.value;
          break;
      }
    });

    return newProject;
  }, [showChangeRequests, data]);

  return [applyChangeRequests];
}

const FETCH = gql`
  query Fetch($id: ID!) {
    externalChangeRequests(
      filters: {
        project: { id: {eq: $id } }
        isCompleted: { eq: false }
      },
      pagination: {limit: 100}
    ) {
      data {
        id
        attributes {
          field
          type
          value
        }
      }
    }
  }
`;
