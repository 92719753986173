import {
  useEffect,
  useState,
} from 'react';
import { MD5 } from 'object-hash';

export function usePaginationReset(resetPagination, ...args) {
  const [initialCall, setInitialCall] = useState(true);
  const argsHash = compare(args);

  useEffect(() => {
    if (!initialCall) {
      resetPagination();
    }

    if (initialCall) {
      setInitialCall(false);
    }
  }, [
    initialCall,
    setInitialCall,
    resetPagination,
    argsHash,
  ]);
}

function compare(args) {
  return MD5(args);
}
