import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LiquidatedTag({ sx }) {
  const { t } = useTranslation();

  return (
    <Chip
      color="primary"
      label={t('components.LiquidatedTag.label')}
      size="small"
      sx={{
        ...sx,
        fontSize: (theme) => theme.typography.caption.fontSize,
      }}
    />
  );
}
