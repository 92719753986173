import {
  paginationDefaultProps,
  paginationPropTypes,
} from '@lib/hooks/usePagination';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const MuiTablePaginationStyled = styled(MuiTablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    display: 'none',
  },
  '& .MuiTablePagination-input': {
    display: 'none',
  },
  '@container (min-width: 500px)': {
    '& .MuiTablePagination-selectLabel': {
      display: 'block',
    },
    '& .MuiTablePagination-input': {
      display: 'inline-flex',
    },
  },
}));

export default function TablePagination(props) {
  const {
    pagination,
    rowsPerPageOptions,
    onPageChange,
    onPageSizeChange,
  } = props;
  const { t } = useTranslation();

  return (
    <MuiTablePaginationStyled
      component="div"
      count={pagination?.total ?? 0}
      labelRowsPerPage={t('components.Pagination.rows')}
      onPageChange={onPageChange}
      onRowsPerPageChange={onPageSizeChange}
      page={pagination?.page ?? 1}
      rowsPerPage={pagination?.pageSize ?? 0}
      rowsPerPageOptions={rowsPerPageOptions}
      showFirstButton
      showLastButton
    />
  );
}

TablePagination.propTypes = {
  ...paginationPropTypes,
};

TablePagination.defaultProps = {
  ...paginationDefaultProps,
};
