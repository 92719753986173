import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';

export default function OrganizationHistory(props) {
  const { organization } = props;
  const { t, i18n } = useTranslation();

  const items = organization.organization_histories;

  return (
    <Box>
      {items.map((item) => (
        <Box key={item.id} mb={2}>
          <Grid container spacing={2}>
            <Grid item sm="auto" xs={12}>
              <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
                {t('modules.organizations.components.OrganizationDetailPage.history.date', {
                  date: DateTime.fromISO(item.date)
                    .setLocale(i18n.language)
                    .toFormat(t('shared.format.date.short')),
                })}
              </Typography>
            </Grid>
            <Grid item sm="auto" xs={12}>
              <Typography variant="body2">
                {item[`name_${i18n.language}`]}
                {item[`abbreviation_${i18n.language}`] && (
                  <span>
                    {' '}
                    (
                    {item[`abbreviation_${i18n.language}`]}
                    )
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

OrganizationHistory.tabFilter = (organization) => (
  organization.organization_histories?.length > 0
);
