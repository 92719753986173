import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import OrganizationHistory from '@modules/organizations/components/organization-details/views/OrganizationHistory';
import OrganizationGeneral from '@modules/organizations/components/organization-details/views/OrganizationGeneral';
import OrganizationTags from '@modules/organizations/components/organization-details/views/OrganizationTags';
import OrganizationProjects from '@modules/organizations/components/organization-details/views/OrganizationProjects';
import { useTranslation } from 'react-i18next';
import OrganizationHead from './OrganizationHead';

export default function MainContent(props) {
  const {
    organization,
  } = props;

  const { t, i18n } = useTranslation();
  const visibleTabs = useMemo(
    () => TABS.filter((tab) => !tab.filter || tab.filter(organization)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization?.id],
  );
  const [view, setView] = useState(visibleTabs[0].id);
  const Component = TABS.find((tab) => tab.id === view).component;

  useEffect(() => {
    setView(visibleTabs[0].id);
  }, [visibleTabs]);

  return (
    <>
      <OrganizationHead organization={organization} />
      <Paper sx={{ mt: 3 }} variant="outlined">
        <Tabs
          indicatorColor="primary"
          onChange={(_, value) => setView(value)}
          scrollButtons="auto"
          sx={{
            px: 2,
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.default',
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
          }}
          textColor="primary"
          value={view}
          variant="scrollable"
        >
          {visibleTabs.map((tab) => (
            <Tab
              key={tab.id}
              label={t(`modules.organizations.components.OrganizationDetailPage.tabs.${tab.id}`)}
              value={tab.id}
            />
          ))}
        </Tabs>
        <Box p={2}>
          <Component organization={organization} />
        </Box>
      </Paper>
    </>
  );
}

const TABS = [
  {
    id: 'general',
    component: OrganizationGeneral,
    filter: OrganizationGeneral.tabFilter,
  },
  {
    id: 'tags',
    component: OrganizationTags,
    filter: OrganizationTags.tabFilter,
  },
  {
    id: 'projects',
    component: OrganizationProjects,
    filter: OrganizationProjects.tabFilter,
  },
  {
    id: 'history',
    component: OrganizationHistory,
    filter: OrganizationHistory.tabFilter,
  },
];
