import {
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  useDispatch,
  useSelector,
} from '@store';
import {
  selectCurrentOrganization,
  setOrganization,
} from '@modules/organizations/state/slices/organization';
import { gql } from '@apollo/client';
import { rootPath } from '@modules/organizations/module';
import { useLazyQuery } from '@lib/hooks';
import { FiX } from 'react-icons/fi';
import LoadingSkeleton from '@modules/organizations/components/organization-details/LoadingSkeleton';
import MainContent from '@modules/organizations/components/organization-details/MainContent';
import { DEFAULT_VIEW } from '@modules/organizations/config';
import trim from 'lodash/trim';
import Head from 'next/head';
import { useExternalChangeRequests } from '@modules/organizations/hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { trackPageView } from '@lib/tracking';

export default function OrganizationDetailsPage(props) {
  const {
    onOpen,
    onClose,
  } = props;

  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { id, showChangeRequests } = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();
  const [fetchOrganization, { data, loading }] = useLazyQuery(FETCH);
  const [applyChangeRequests] = useExternalChangeRequests(id, showChangeRequests);

  useEffect(() => {
    const handleRouteChange = (/* url, { shallow } */) => dispatch(setOrganization(null));
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, dispatch]);
  useEffect(() => {
    if (id) {
      fetchOrganization({
        variables: {
          id,
          locale: i18n.language,
        },
      })
        .then(() => {
          trackPageView(window.location.href.split('?')[0]);
        })
        .catch(console.error);
    }
  }, [fetchOrganization, id, i18n.language]);
  useEffect(() => {
    if (id && onOpen) {
      onOpen(id);
    }
  }, [onOpen, id]);

  const handleClose = useCallback(() => {
    dispatch(setOrganization(null));

    if (!window.location.href.includes('/member')) {
      restoreUrl();
    }

    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  let organization = data?.calculatedOrganization;
  organization = applyChangeRequests(organization);

  const hasAnyLiquidatedOrgs = organization?.liquidationState === 'liquidated';

  return (
    <>
      {Boolean(id) && (
        <Head>
          <title>
            {loading || !organization ? '...' : organization.name}
          </title>
          <meta content={organization?.name} property="og:title" />
          {organization?.logos.slice(0, 1).map((logo) => (
            <meta key={logo.url} content={logo.url} property="og:image" />
          ))}
        </Head>
      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        open={Boolean(id)}
        scroll="paper"
      >
        <DialogTitle>
          {loading || !organization ? (
            <Skeleton sx={{ maxWidth: 300 }} />
          ) : t('modules.organizations.components.organizationDetails.OrganizationDetailPage.organization')}
        </DialogTitle>
        <Tooltip placement="left" title={t('modules.organizations.components.organizationDetails.OrganizationDetailPage.closeOrganization')}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FiX />
          </IconButton>
        </Tooltip>
        <DialogContent sx={{ py: 0 }}>
          <Paper
            sx={{
              pb: 2,
              backgroundColor: hasAnyLiquidatedOrgs ? '#FFEBEE' : 'background.paper',
              border: 'none',
            }}
            variant="outlined"
          >
            {loading || !organization
              ? <LoadingSkeleton />
              : (
                <MainContent organization={organization} />
              )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('modules.organizations.components.organizationDetails.OrganizationDetailPage.closeOrganization')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function useOrganizationDetailsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const organizationId = url.searchParams.get('id');

    if (organizationId) {
      dispatch(setOrganization({ id: organizationId }));
      applyUrl(organizationId, 'list');
    }
  }, [dispatch]);

  return {
    openOrganizationDetailsPage: (id, view, showChangeRequests = false) => {
      dispatch(setOrganization({ id, showChangeRequests }));

      if (view) {
        applyUrl(id, view);
      }
    },
  };
}

function applyUrl(id, view) {
  const url = new URL(window.location.href);
  url.searchParams.delete('id');
  url.searchParams.set('view', view);
  let newUrl = `${rootPath}/${id}`;
  newUrl = trim(`${newUrl}?${url.searchParams.toString()}`, ['?', '&']);
  window.history.pushState(null, '', newUrl);
}

function restoreUrl() {
  const url = new URL(window.location.href);
  const view = url.searchParams.get('view') || DEFAULT_VIEW;
  url.searchParams.delete('view');
  let newUrl = `${rootPath}/${view}`;
  newUrl = trim(`${newUrl}?${url.searchParams.toString()}`, ['?', '&']);
  window.history.pushState(null, '', newUrl);
}

const FETCH = gql`
  query Fetch($id: ID!, $locale: String!) {
    calculatedOrganization(id: $id, locale: $locale)
  }
`;
