export function useOrganizationQueryFilters(search, { filter }) {
  const queryFilter = {
    isDeleted: { eq: false },
    publicationState: { ne: 'draft' },
    isHiddenInCompanyDatabase: { eq: false },
  };

  const {
    organizationFilters,
    showLiquidatedOrgsFilter,
  } = filter;

  if (filter.organizationTypeFilter) {
    queryFilter.organizationType = {
      in: filter.organizationTypeFilter,
    };
  }

  if (organizationFilters?.addressCity) {
    queryFilter.addressCity = { containsi: organizationFilters.addressCity };
  }

  if (organizationFilters?.addressState) {
    queryFilter.addressState = { containsi: organizationFilters.addressState };
  }

  // if (organizationFilters?.addressCountry) {
  // queryFilter.addressCountry= { containsi: organizationFilters.addressCountry };
  // }

  if (!showLiquidatedOrgsFilter) {
    queryFilter.liquidationState = { eq: 'active' };
  }

  if (organizationFilters?.tags) {
    organizationFilters.tags.forEach((tag) => {
      if (tag.fullTreeFromSelection?.length > 0) {
        queryFilter.and = queryFilter.and || [];
        queryFilter.and.push({
          tag_items: {
            tag_item: {
              id: {
                in: tag.fullTreeFromSelection,
              },
            },
          },
        });
      }
    });
  }

  if (search) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      or: populateSearchOrFilter(search),
    });
  }

  return queryFilter;
}

function populateSearchOrFilter(search, level = 0) {
  if (level === 10) {
    return [];
  }

  return [
    { name_de: { containsi: search } },
    { name_en: { containsi: search } },
    { abbreviation_de: { containsi: search } },
    { abbreviation_en: { containsi: search } },
    { organization_histories: { name_de: { containsi: search } } },
    { organization_histories: { name_en: { containsi: search } } },
    { organization_histories: { abbreviation_de: { containsi: search } } },
    { organization_histories: { abbreviation_en: { containsi: search } } },
    {
      parent_organizations: {
        and: [
          {
            organizationType: { ne: 'press' },
          },
          {
            or: populateSearchOrFilter(search, level + 1),
          },
        ],
      },
    },
  ];
}
